import Vue from "vue";
import Router from "vue-router";
import { Routes } from "../utils/routes";
import { Constants } from "../utils/constants";
import { Api } from "../utils/http-common";
import { Urls } from "../utils/urls";
// import moment from "moment";
import store from "../store/index";

// Layout
const LayoutAuth = () => import("@/components/auth/Layout");
const LayoutAdmin = () => import("@/components/admin/Layout");
const LayoutMaster = () => import("@/components/master/Layout");
const LayoutHome = () => import("@/components/default/Layout");
const LayoutPage404 = () => import("@/components/page404/Layout");
const LayoutMaintenance = () => import("@/components/maintenance/Layout");
const LayoutTestLibrary = () =>
  import("@/components/default/LayoutTestLibrary");
// Auth
const Login = () => import("@/views/auth/Login");
const CreateMember = () => import("@/views/auth/CreateMember");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const ForgotPasswordAdmin = () => import("@/views/admin/ForgotPasswordAdmin");
const ForgotPasswordCDEA = () => import("@/views/auth/ForgotPasswordCDEA");
const ChangePassword = () => import("@/views/auth/ChangePassword");
const FacebookLogin = () => import("@/views/auth/FacebookLogin");
const LineLogin = () => import("@/views/auth/LineLogin");
const TwitterLogin = () => import("@/views/auth/TwitterLogin");
const AmazonLogin = () => import("@/views/auth/AmazonLogin");
const ActiveMail = () => import("@/views/auth/ActiveMail");
//slag
const CreateSlag = () => import("@/views/admin/CreateSlag");
const ListSlag = () => import("@/views/admin/ListSlag");
// const EditSlag = () => import("@/views/admin/EditSlag");
//content
const ListContent = () => import("@/views/admin/ListContent");
const ListSale = () => import("@/views/admin/ListSale");
const CreateContent = () => import("@/views/admin/CreateContent");
const EditContent = () => import("@/views/admin/EditContent");
// DashBoard
const DashBoardCDEA = () => import("@/views/master/DashBoard");
const ListOwner = () => import("@/views/master/ListOwner");
const CreateOwner = () => import("@/views/master/CreateOwner");
// const ListUserMaster = () => import("@/views/master/ListUser");
// const CreateUserMaster = () => import("@/views/master/CreateUser");
// const EditUserMaster = () => import("@/views/master/EditUser");

//Home
const Library = () => import("@/views/default/Library");
// const Content = () => import("@/views/default/Content");
const Page = () => import("@/views/default/Page");
const Store = () => import("@/views/default/Store");
const ContentDetail = () => import("@/views/default/ContentDetail");
const AuthorDetail = () => import("@/views/default/AuthorDetail");
const StoreAuthor = () => import("@/views/default/StoreAuthor");
const HistoryPoint = () => import("@/views/default/HistoryPoint");
const Author = () => import("@/views/default/Author");
//Default
const CreateOwnerDefault = () => import("@/views/default/CreateOwner");
//Page
const ListPage = () => import("@/views/admin/ListPage");
const CreatePage = () => import("@/views/admin/CreatePage");
const EditPage = () => import("@/views/admin/EditPage");
//User Admin
const ListUser = () => import("@/views/admin/ListUser");
const CreateUser = () => import("@/views/admin/CreateUser");
const PurchaseUser = () => import("@/views/admin/PurchaseUser");
//Cart
const Cart = () => import("@/views/default/Cart");
// List New User
const ListNewUser = () => import("@/views/default/ListNew");
const DetailNewUser = () => import("@/views/default/DetailNew");
// Checkout
const Checkout = () => import("@/views/default/Checkout");
const Payment = () => import("@/views/default/Payment");
const ConfirmPayment = () => import("@/views/default/ConfirmPayment");
const ExternalPaymentRedirect = () => import("@/views/auth/ExternalPaymentRedirect");
const ExternalPaymentCheck = () => import("@/views/default/ExternalPaymentCheck");
const ExternalPaymentApproval = () => import("@/views/admin/ExternalPaymentApproval");
// List checkout
const CheckoutAdmin = () => import("@/views/admin/Checkout");
const CheckoutAdminDetail = () => import("@/views/admin/CheckoutDetail");
//Point
const ListPoint = () => import("@/views/admin/ListPoint");
const CreatePoint = () => import("@/views/admin/CreatePoint");
// const EditPoint = () => import("@/views/admin/EditPoint");
// List New Admin
const ListNewAdmin = () => import("@/views/admin/ListNew");
const CreateNewAdmin = () => import("@/views/admin/CreateNew");
const ListNewCDEA = () => import("@/views/master/ListNew");
const CreateNewCDEA = () => import("@/views/master/CreateNew");
//Q&A
const ListQA = () => import("@/views/admin/ListQA");

//Dashboard Admim
const DashboardAdmin = () => import("@/views/admin/Dashboard");
const DashboardAdminCreate = () => import("@/views/admin/DashboardCreate");
const DashboardAdminEdit = () => import("@/views/admin/DashboardEdit");
const DashboardAdminBox = () => import("@/views/admin/DashboardBox");
const DashboardAdminSetting = () => import("@/views/admin/DashboardSetting");
const DashboardAdminOther = () => import("@/views/admin/DashboardOther");
// const DashboardFunction = () => import("@/views/admin/ListFunction");
//Table of ContentsEdit\
const TableOfContentEdit = () => import("@/views/admin/TableOfContentsEdit");

//Login admin
const LoginAdmin = () => import("@/views/admin/Login");

//Login cdea
const LoginCDEA = () => import("@/views/master/Login");

//Category
const ListCategory = () => import("@/views/admin/ListCategory");
const CreateCategory = () => import("@/views/admin/CreateCategory");
const EditCategory = () => import("@/views/admin/EditCategory");

// view - page
const Page404 = () => import("../views/pages/page404");
const Page404Admin = () => import("@/views/admin/pages404");
const Page404All = () => import("@/views/pages/page404All");
const Page404CDEA_Admin = () => import("@/views/master/page404");
const PageNotice = () => import("@/views/pages/pageNotice");
const PageMaintenance = () => import("@/views/pages/pageMaintenance");

//Detail Content Library
const DetailContentLibrary = () =>
  import("@/views/default/DetailContentLibrary");

const DetailPageLibrary = () => import("@/views/default/DetailPageLibrary");
const ReviewPage = () => import("@/views/admin/ReviewPage");
//ListNote

//List Order
const ListOrders = () => import("@/views/admin/ListOrder");
const EditOrders = () => import("@/views/admin/EditOrder");

//Email Setting
const EmailSetting = () => import("@/views/admin/EmailSetting");
const SettingStore = () => import("@/views/admin/SettingStore");
const DesignSetting = () => import("@/views/admin/DesignSetting");

//Inquiry
const ListInquiry = () => import("@/views/admin/ListInquiry");
const ListRealtimeActivity = () => import("@/views/admin/ListRealtimeActivity");
const DetailInquiry = () => import("@/views/admin/DetailInquiry");
const CreateInquiry = () => import("@/views/admin/CreateInquiry");
const CreateInquiryUser = () => import("@/views/default/CreateInquiry");

//Survey
const ListSurvey = () => import("@/views/admin/ListSurvey");
const CreateSurvey = () => import("@/views/admin/CreateSurvey");
const EditSurvey = () => import("@/views/admin/CreateSurvey");

//List User Note
const ListUserNote = () => import("@/views/admin/ListUserNote");
const ListNoteByContent = () => import("@/views/admin/ListNoteByContent");

//List Author
const ListAuthors = () => import("@/views/admin/ListAuthor");
// create/edit - Author
const CreateAuthors = () => import("@/views/admin/CreateAuthor");

// SaleManagetment
const SaleManagementMonthly = () =>
  import("@/views/admin/SaleManagementMonthly");
//My page
const MyProfile = () => import("@/views/default/MyProfile");

//Setting header
const MenuManager = () => import("@/views/admin/MenuManager");
const HeaderSetting = () => import("@/views/admin/HeaderSetting");
const FooterSetting = () => import("@/views/admin/FooterSetting");
const BottomSetting = () => import("@/views/admin/BottomNavigationSetting");
const SaleManagementDaily = () => import("@/views/admin/SaleManagementDaily");
const DetailSaleManagementDaily = () =>
  import("@/views/admin/DetailSaleManagementDaily");
const SaleManagementRanking = () =>
  import("@/views/admin/SaleManagementRanking");

const ListNoteContent = () => import("@/views/default/ListNoteContent");

//Fixed page
const ListFixedPage = () => import("@/views/admin/ListFixedPage");
const CreateFixedPage = () => import("@/views/admin/CreateFixedPage");
const PreviewPage = () => import("@/views/default/PreviewPage");
const PreviewColumnPage = () => import("@/views/admin/PreviewColumn");
const PageFixed = () => import("@/views/default/PageStatic");
const PageColumn = () => import("@/views/default/PageColumn");

//List Category New
const ListCategoryNew = () => import("@/views/admin/ListCategoryNew");
const EditCategoryNew = () => import("@/views/admin/EditCategoryNew");

//Global Setting
const GlobalSetting = () => import("@/views/admin/GlobalSetting");
const PaymentSetting = () => import("@/views/admin/PaymentSetting");

//Timing popup
const TimingPopupManagement = () =>
  import("@/views/admin/TimingPopupManagement");
const CreateTimingPopup = () => import("@/views/admin/CreateTimingPopup");

//Sales Setting
const SalesSetting = () => import("@/views/admin/SalesSetting");

//Timming Mail
const TimingMail = () => import("@/views/admin/Timing_Email_Management");
const CreateTimingMail = () => import("@/views/admin/CreateTimingMail");
const EditTimingMail = () => import("@/views/admin/CreateTimingMail");

//General Setting
const GeneralSetting = () => import("@/views/admin/General_Settings");

//URL Manager
const URLManager = () => import("@/views/admin/URLManager");
const CreateURL = () => import("@/views/admin/CreateURL");

//SNS Setting
const SNSSetting = () => import("@/views/admin/SNSSetting");

//Menu Sale Management
const MenuSaleManagement = () => import("@/views/admin/MenuSaleManagement");

//List Note All user
const ListNote = () => import("@/views/admin/ListNote");

//List Cart Admin
const ListCart = () => import("@/views/admin/ListCart");

//List Trigger
const ListTrigger = () => import("@/views/admin/ListTrigger");
const CreateTrigger = () => import("@/views/admin/CreateTrigger");

//Delete account
const DeleteAccount = () => import("@/views/default/DeleteAccount");

const ContentChange = () => import("@/views/admin/ContentChange");
const ContentSale = () => import("@/views/admin/ContentEditSale");
const ContentUrl = () => import("@/views/admin/ContentUrl");

//List Mail Mega
const MailMega = () => import("@/views/admin/ListMailMega");
const CreateMailMega = () => import("@/views/admin/CreateMailMega");

//List Chapter Managerment
const ChapterManagerment = () => import("@/views/admin/ListChapter");
const CreateChapterManagerment = () => import("@/views/admin/CreateChapter");

//Help page
const HelpPage = () => import("@/views/default/HelpPage");

//Point Definition
const Purchasepoint = () => import("@/views/admin/ListPurchasePoint");
const CreatePurchasepoint = () => import("@/views/admin/CreatePurchasePoint");

//Before Login
const BeforeLogin = () => import("@/views/auth/BeforeLogin");
const BeforeSignUp = () => import("@/views/auth/BeforeCreateMember");
const tesst = () => import("@/views/test");

//Test Library
const TestLibrary = () => import("@/views/test/Library");

//Chat Admin
const ListChatAdmin = () => import("@/views/admin/ListChatAdmin");
const ListChatUser = () => import("@/views/admin/ListChatUser");
const DetailChatAdmin = () => import("@/views/admin/DetailChatAdmin");

// List Chat Content
const ListChatContent = () => import("@/views/default/ListChatContent");
//PageThank
const PageThank = () => import("@/views/default/PageThank");
const ContentdisplayConfirmation = () =>
  import("@/views/admin/ContentdisplayConfirmation");

// Coupon
// const ListCoupon = () => import("@/views/admin/ListCoupon");
// const CreateCoupon = () => import("@/views/admin/CreateCoupon");

// Sentense
const ListSentence = () => import("@/views/admin/ListSentence");
const CreateSentence = () => import("@/views/admin/CreateSentence");

const CreateMyProfile = () => import("@/views/admin/CreateMyProfile");

Vue.use(Router);
let router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
  beforeEnter: removeQueryParams(),
});
function configRoutes() {
  return [
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      component: LayoutTestLibrary,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.LIBRARY_TEST}`,
          name: "test library",
          component: TestLibrary,
          meta: {
            label: "list owner",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.LIBRARY_TEST}`,
          name: "test library domain",
          component: TestLibrary,
          meta: {
            label: "list owner domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      component: LayoutMaster,
      children: [
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.OWNERS}`,
          name: "list owner",
          component: ListOwner,
          meta: {
            title: "Page not found",
            label: "list owner",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}`,
          name: "dashboard cdea",
          component: DashBoardCDEA,
          meta: {
            title: "Page not found",
            label: "dashboard cdea",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.OWNERS}/${Routes.CREATE}`,
          name: "create owner",
          component: CreateOwner,
          meta: {
            title: "店主",
            label: "create owner",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.OWNERS}/${Routes.EDIT}/:id`,
          name: "edit owner",
          component: CreateOwner,
          meta: {
            title: "店主",
            label: "edit owner",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.NEWS}`,
          name: "list new cdea",
          component: ListNewCDEA,
          meta: {
            label: "list new cdea",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.NEWS}/${Routes.CREATE}`,
          name: "create new cdea",
          component: CreateNewCDEA,
          meta: {
            label: "create new cdea",
            requiresAuth: true,
            userType: [0],
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.DASHBOARD}/${Routes.NEWS}/${Routes.EDIT}/:id`,
          name: "edit new cdea",
          component: CreateNewCDEA,
          meta: {
            label: "edit new cdea",
            requiresAuth: true,
            userType: [0],
          },
        },
        // {
        //   path: `/${Routes.USER}`,
        //   name: "list user master",
        //   component: ListUserMaster,
        //   meta: {
        //     label: "list user master",
        //     requiresAuth: true,
        //     userType: [0],
        //   },
        // },
        // {
        //   path: `/${Routes.USER}/${Routes.CREATE}`,
        //   name: "create user master",
        //   component: CreateUserMaster,
        //   meta: {
        //     label: "create user master",
        //     requiresAuth: true,
        //     userType: [0],
        //   },
        // },
        // {
        //   path: `/${Routes.USER}/${Routes.EDIT}/:id`,
        //   name: "edit user master",
        //   component: EditUserMaster,
        //   meta: {
        //     label: "edit user master",
        //     requiresAuth: true,
        //     userType: [0],
        //   },
        // },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}`,
      component: LayoutAdmin,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}`,
          name: "Dashboard Admin",
          component: DashboardAdmin,
          meta: {
            label: "Dashboard Admin",
            title: "ホーム",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}`,
          name: "Dashboard Admin domain",
          component: DashboardAdmin,
          meta: {
            label: "Dashboard Admin domain",
            title: "ホーム",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.CREATE}`,
          name: "DashboardAdminCreate",
          component: DashboardAdminCreate,
          meta: {
            title: "作成メニュー",
            label: "DashboardAdminCreate",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.CREATE}`,
          name: "DashboardAdminCreate domain",
          component: DashboardAdminCreate,
          meta: {
            title: "作成メニュー",
            label: "DashboardAdminCreate domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.EDIT}`,
          name: "DashboardAdminEdit",
          component: DashboardAdminEdit,
          meta: {
            title: "編集メニュー",
            label: "DashboardAdminEdit",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.EDIT}`,
          name: "DashboardAdminEdit domain",
          component: DashboardAdminEdit,
          meta: {
            title: "編集メニュー",
            label: "DashboardAdminEdit domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.BOX}`,
          name: "DashboardAdminBox",
          component: DashboardAdminBox,
          meta: {
            title: "ツールBOX",
            label: "DashboardAdminBox",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.BOX}`,
          name: "DashboardAdminBox domain",
          component: DashboardAdminBox,
          meta: {
            title: "ツールBOX",
            label: "DashboardAdminBox domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.SETTING}`,
          name: "DashboardAdminSetting",
          component: DashboardAdminSetting,
          meta: {
            title: "全体設定",
            label: "DashboardAdminSetting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.SETTING}`,
          name: "DashboardAdminSetting domain",
          component: DashboardAdminSetting,
          meta: {
            title: "全体設定",
            label: "DashboardAdminSetting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.OTHER}`,
          name: "DashboardAdminOther",
          component: DashboardAdminOther,
          meta: {
            title: "他のメニュー",
            label: "DashboardAdminOther",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.OTHER}`,
          name: "DashboardAdminOther domain",
          component: DashboardAdminOther,
          meta: {
            title: "他のメニュー",
            label: "DashboardAdminOther domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DASHBOARD}/${Routes.OTHER}`,
        //   name: "ListFunction",
        //   component: DashboardFunction,
        //   meta: {
        //     title: "その他",
        //     label: "ListFunction",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin",
        //       parent: "ホーム",
        //     },
        //   },
        // },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SLAG}`,
          name: "list slag",
          component: ListSlag,
          meta: {
            title: "スラグ管理",
            label: "slag",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SLAG}`,
          name: "list slag domain",
          component: ListSlag,
          meta: {
            title: "スラグ管理",
            label: "slag domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SLAG}/${Routes.CREATE}`,
          name: "create slag",
          component: CreateSlag,
          meta: {
            title: "新規作成",
            label: "slag",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb1: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb3: {
              url: "list slag",
              parent: "スラグ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SLAG}/${Routes.CREATE}`,
          name: "create slag domain",
          component: CreateSlag,
          meta: {
            title: "新規作成",
            label: "slag domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb1: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb3: {
              url: "list slag domain",
              parent: "スラグ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SLAG}/${Routes.EDIT}/:id`,
          name: "edit slag",
          component: CreateSlag,
          meta: {
            title: "編集",
            label: "slag",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb1: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb3: {
              url: "list slag",
              parent: "スラグ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SLAG}/${Routes.EDIT}/:id`,
          name: "edit slag domain",
          component: CreateSlag,
          meta: {
            title: "編集",
            label: "slag domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb1: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb3: {
              url: "list slag domain",
              parent: "スラグ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}`,
          name: "list content",
          component: ListContent,
          meta: {
            title: "コンテンツ管理",
            url: "list content",
            label: "content",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}`,
          name: "list content domain",
          component: ListContent,
          meta: {
            title: "コンテンツ管理",
            url: "list content domain",
            label: "content",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALE}`,
          name: "list sale",
          component: ListSale,
          meta: {
            title: "リストセール",
            url: "list sale",
            label: "sale",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALE}`,
          name: "list sale domain",
          component: ListSale,
          meta: {
            title: "リストセール",
            url: "list sale domain",
            label: "sale",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CHANGE}/${Routes.CREATE}`,
          name: "create content",
          component: CreateContent,
          meta: {
            label: "create content",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CHANGE}/${Routes.CREATE}`,
          name: "create content domain",
          component: CreateContent,
          meta: {
            label: "create content domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CHANGE}/${Routes.EDIT}/:id`,
          name: "edit content",
          component: EditContent,
          meta: {
            label: "edit content",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CHANGE}/${Routes.EDIT}/:id`,
          name: "edit content domain",
          component: EditContent,
          meta: {
            label: "edit content domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGE}`,
          name: "list page",
          component: ListPage,
          meta: {
            title: "ページ管理",
            label: "list page",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PAGE}`,
          name: "list page domain",
          component: ListPage,
          meta: {
            title: "ページ管理",
            label: "list page domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGE}/${Routes.CREATE}`,
          name: "create page",
          component: CreatePage,
          meta: {
            title: "新規作成",
            label: "create page",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PAGE}/${Routes.CREATE}`,
          name: "create page domain",
          component: CreatePage,
          meta: {
            title: "新規作成",
            label: "create page domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page domain",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGE}/create2`,
          name: "create page",
          component: tesst,
          meta: {
            title: "新規作成",
            label: "create page",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PAGE}/create2`,
          name: "create page domain",
          component: tesst,
          meta: {
            title: "新規作成",
            label: "create page domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page domain",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGE}/${Routes.EDIT}/:id`,
          name: "edit page",
          component: EditPage,
          meta: {
            title: "編集",
            label: "edit page",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PAGE}/${Routes.EDIT}/:id`,
          name: "edit page domain",
          component: EditPage,
          meta: {
            title: "編集",
            label: "edit page domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list page domain",
              parent: "ページ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}`,
          name: "list user",
          component: ListUser,
          meta: {
            title: "ユーザー管理",
            label: "list user",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}`,
          name: "list user domain",
          component: ListUser,
          meta: {
            title: "ユーザー管理",
            label: "list user domain",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/${Routes.CREATE}`,
          name: "create user",
          component: CreateUser,
          meta: {
            title: "新規作成",
            label: "create user",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/${Routes.CREATE}`,
          name: "create user domain",
          component: CreateUser,
          meta: {
            title: "新規作成",
            label: "create user domain",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user domain",
              parent: "ユーザー",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/${Routes.EDIT}/:id`,
          name: "edit user",
          component: CreateUser,
          meta: {
            title: "編集",
            label: "edit user",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/${Routes.EDIT}/:id`,
          name: "edit user domain",
          component: CreateUser,
          meta: {
            title: "編集",
            label: "edit user domain",
            requiresAuth: true,
            userType: [0, 1, 2],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user domain",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/${Routes.DETAIL}/:id`,
          name: "detail user",
          component: CreateUser,
          meta: {
            label: "detail user",
            requiresAuth: true,
            userType: [3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/${Routes.DETAIL}/:id`,
          name: "detail user domain",
          component: CreateUser,
          meta: {
            label: "detail user domain",
            requiresAuth: true,
            userType: [3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/:id/${Routes.PURCHASE}`,
          name: "purchase user",
          component: PurchaseUser,
          meta: {
            title: "ユーザー購入履歴",
            label: "purchase user",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/:id/${Routes.PURCHASE}`,
          name: "purchase user domain",
          component: PurchaseUser,
          meta: {
            title: "ユーザー購入履歴",
            label: "purchase user domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user domain",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/:shopId/${Routes.ADMIN}/${Routes.CONFIRM_CHECKOUT}`,
          name: "list checkout",
          component: CheckoutAdmin,
          meta: {
            label: "list checkout",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONFIRM_CHECKOUT}`,
          name: "list checkout domain",
          component: CheckoutAdmin,
          meta: {
            label: "list checkout domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/:shopId/${Routes.ADMIN}/${Routes.CONFIRM_CHECKOUT}/:id`,
          name: "checkout detail",
          component: CheckoutAdminDetail,
          meta: {
            label: "checkout detail",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONFIRM_CHECKOUT}/:id`,
          name: "checkout detail domain",
          component: CheckoutAdminDetail,
          meta: {
            label: "checkout detail domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.POINT}`,
          name: "list point",
          component: ListPoint,
          meta: {
            title: "ポイント設定",
            label: "list point",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT}`,
          name: "list point domain",
          component: ListPoint,
          meta: {
            title: "ポイント設定",
            label: "list point domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.POINT}/${Routes.CREATE}`,
          name: "create point",
          component: CreatePoint,
          meta: {
            title: "新規作成",
            label: "create point",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list point",
              parent: "ポイント設定",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT}/${Routes.CREATE}`,
          name: "create point domain",
          component: CreatePoint,
          meta: {
            title: "新規作成",
            label: "create point domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list point domain",
              parent: "ポイント設定",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.POINT}/${Routes.EDIT}/:id`,
          name: "edit point",
          component: CreatePoint,
          meta: {
            title: "編集",
            label: "edit point",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list point",
              parent: "ポイント設定",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT}/${Routes.EDIT}/:id`,
          name: "edit point domain",
          component: CreatePoint,
          meta: {
            title: "編集",
            label: "edit point domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list point domain",
              parent: "ポイント設定",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/:shopId/${Routes.ADMIN}/${Routes.QA}`,
          name: "list Q&A",
          component: ListQA,
          meta: {
            label: "list Q&A",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.QA}`,
          name: "list Q&A domain",
          component: ListQA,
          meta: {
            label: "list Q&A domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TABLEOFCONTENTEDIT}/:id`,
          name: "Table Of ContentEdit",
          component: TableOfContentEdit,
          meta: {
            label: "Table Of ContentEdit",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TABLEOFCONTENTEDIT}/:id`,
          name: "Table Of ContentEdit domain",
          component: TableOfContentEdit,
          meta: {
            label: "Table Of ContentEdit domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY}`,
          name: "List Category",
          component: ListCategory,
          meta: {
            title: "カテゴリ管理",
            label: "List Category",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY}`,
          name: "List Category domain",
          component: ListCategory,
          meta: {
            title: "カテゴリ管理",
            label: "List Category domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY}/${Routes.CREATE}`,
          name: "Create Category",
          component: CreateCategory,
          meta: {
            title: "新規作成",
            label: "Create Category",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
            breadcrumb3: {
              url: "List Category",
              parent: "カテゴリー個別設定（カバー）",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY}/${Routes.CREATE}`,
          name: "Create Category domain",
          component: CreateCategory,
          meta: {
            title: "新規作成",
            label: "Create Category domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
            breadcrumb3: {
              url: "List Category domain",
              parent: "カテゴリー個別設定（カバー）",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY}/${Routes.EDIT}/:id`,
          name: "Edit Category",
          component: EditCategory,
          meta: {
            title: "編集",
            label: "Edit Category",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
            breadcrumb3: {
              url: "List Category",
              parent: "カテゴリー個別設定（カバー）",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY}/${Routes.EDIT}/:id`,
          name: "Edit Category domain",
          component: EditCategory,
          meta: {
            title: "編集",
            label: "Edit Category domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
            breadcrumb3: {
              url: "List Category domain",
              parent: "カテゴリー個別設定（カバー）",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/:idUser/${Routes.NOTES}/:idContent/${Routes.NO}/:no`,
          name: "ListNoteByContent",
          component: ListNoteByContent,
          meta: {
            title: "ノート確認",
            label: "ListNoteByContent",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "ListUserNote",
              parent: "ノート一覧",
            },
            breadcrumb3: {
              url: "list user",
              parent: "ユーザー管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/:idUser/${Routes.NOTES}/:idContent/${Routes.NO}/:no`,
          name: "ListNoteByContent domain",
          component: ListNoteByContent,
          meta: {
            title: "ノート確認",
            label: "ListNoteByContent domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "ListUserNote domain",
              parent: "ノート一覧",
            },
            breadcrumb3: {
              url: "list user domain",
              parent: "ユーザー管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.ORDER}`,
          name: "ListOrder",
          component: ListOrders,
          meta: {
            title: "注文管理",
            label: "ListOrder",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.ORDER}`,
          name: "ListOrder domain",
          component: ListOrders,
          meta: {
            title: "注文管理",
            label: "ListOrder domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.ORDER}/${Routes.EDIT}/:id`,
          name: "EditOrder",
          component: EditOrders,
          meta: {
            title: "編集",
            label: "EditOrder",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "ListOrder",
              parent: "注文管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.ORDER}/${Routes.EDIT}/:id`,
          name: "EditOrder domain",
          component: EditOrders,
          meta: {
            title: "編集",
            label: "EditOrder domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "ListOrder domain",
              parent: "注文管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.USER}/:id/${Routes.NOTE}`,
          name: "ListUserNote",
          component: ListUserNote,
          meta: {
            title: "ノート一覧",
            label: "ListUserNote",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.USER}/:id/${Routes.NOTE}`,
          name: "ListUserNote domain",
          component: ListUserNote,
          meta: {
            title: "ノート一覧",
            label: "ListUserNote domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list user domain",
              parent: "ユーザー管理",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.AUTHOR}`,
          name: "ListAuthor",
          component: ListAuthors,
          meta: {
            title: "著者アカウント",
            label: "ListAuthor",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.AUTHOR}`,
          name: "ListAuthor domain",
          component: ListAuthors,
          meta: {
            title: "著者アカウント",
            label: "ListAuthor domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.AUTHOR}/${Routes.CREATE}`,
          name: "CreateAuthor",
          component: CreateAuthors,
          meta: {
            title: "新規作成",
            label: "CreateAuthor",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "ListAuthor",
              parent: "著者アカウント",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.AUTHOR}/${Routes.CREATE}`,
          name: "CreateAuthor domain",
          component: CreateAuthors,
          meta: {
            title: "新規作成",
            label: "CreateAuthor domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "ListAuthor domain",
              parent: "著者アカウント",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.AUTHOR}/${Routes.EDIT}/:id`,
          name: "editAuthor",
          component: CreateAuthors,
          meta: {
            title: "編集",
            label: "editAuthor",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "ListAuthor",
              parent: "著者アカウント",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.AUTHOR}/${Routes.EDIT}/:id`,
          name: "editAuthor domain",
          component: CreateAuthors,
          meta: {
            title: "編集",
            label: "editAuthor domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "ListAuthor domain",
              parent: "著者アカウント",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.NEWS}`,
          name: "list new admin",
          component: ListNewAdmin,
          meta: {
            label: "list new admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.NEWS}`,
          name: "list new admin domain",
          component: ListNewAdmin,
          meta: {
            label: "list new admin domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.NEWS}/${Routes.CREATE}`,
          name: "create new admin",
          component: CreateNewAdmin,
          meta: {
            title: "ニュース作成",
            label: "create new admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.NEWS}/${Routes.CREATE}`,
          name: "create new admin domain",
          component: CreateNewAdmin,
          meta: {
            title: "ニュース作成",
            label: "create new admin domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.NEWS}/${Routes.EDIT}/:id`,
          name: "edit new admin",
          component: CreateNewAdmin,
          meta: {
            title: "編集",
            label: "edit new admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.NEWS}/${Routes.EDIT}/:id`,
          name: "edit new admin domain",
          component: CreateNewAdmin,
          meta: {
            title: "編集",
            label: "edit new admin domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.MONTHLY}`,
          name: "saleManagementMonthly",
          component: SaleManagementMonthly,
          meta: {
            title: "月別",
            label: "saleManagementMonthly",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.MONTHLY}`,
          name: "saleManagementMonthly domain",
          component: SaleManagementMonthly,
          meta: {
            title: "月別",
            label: "saleManagementMonthly domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment domain",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.MENU}`,
          name: "menuManager",
          component: MenuManager,
          meta: {
            title: "メニュー設定",
            label: "menuManager",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.MENU}`,
          name: "menuManager domain",
          component: MenuManager,
          meta: {
            title: "メニュー設定",
            label: "menuManager domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.MENU}/${Routes.HEADER}`,
          name: "headerSetting",
          component: HeaderSetting,
          meta: {
            title: "ヘッダーメニュー管理",
            label: "headerSetting",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.MENU}/${Routes.HEADER}`,
          name: "headerSetting domain",
          component: HeaderSetting,
          meta: {
            title: "ヘッダーメニュー管理",
            label: "headerSetting domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager domain",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.MENU}/${Routes.FOOTER}`,
          name: "footerSetting",
          component: FooterSetting,
          meta: {
            title: "フッターメニュー管理",
            label: "footerSetting",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.MENU}/${Routes.FOOTER}`,
          name: "footerSetting domain",
          component: FooterSetting,
          meta: {
            title: "フッターメニュー管理",
            label: "footerSetting domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager domain",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.BOTTOM}`,
          name: "bottomSetting",
          component: BottomSetting,
          meta: {
            title: "ボトムナビゲーション管理",
            label: "bottomSetting",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.BOTTOM}`,
          name: "bottomSetting domain",
          component: BottomSetting,
          meta: {
            title: "ボトムナビゲーション管理",
            label: "bottomSetting domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "全体設定",
            },
            breadcrumb2: {
              url: "menuManager domain",
              parent: "メニュー設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.DAILY}/:daily`,
          name: "saleManagementdaily",
          component: SaleManagementDaily,
          meta: {
            title: "日別",
            label: "saleManagementdaily",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.DAILY}/:daily`,
          name: "saleManagementdaily domain",
          component: SaleManagementDaily,
          meta: {
            title: "日別",
            label: "saleManagementdaily domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment domain",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.DAILY}/${Routes.DETAIL}/:daily`,
          name: "detailSaleManagementDaily",
          component: DetailSaleManagementDaily,
          meta: {
            title: "日別詳細",
            label: "detailSaleManagementDaily",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.DAILY}/${Routes.DETAIL}/:daily`,
          name: "detailSaleManagementDaily domain",
          component: DetailSaleManagementDaily,
          meta: {
            title: "日別詳細",
            label: "detailSaleManagementDaily domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "Menu SaleManagerment domain",
              parent: "売上管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.RANK}`,
          name: "listSaleManagementRanks",
          component: SaleManagementRanking,
          meta: {
            label: "listSaleManagementRanks",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.RANK}`,
          name: "listSaleManagementRanks domain",
          component: SaleManagementRanking,
          meta: {
            label: "listSaleManagementRanks domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.EMAIL_SETTING}`,
          name: "email setting",
          component: EmailSetting,
          meta: {
            title: "メール設定",
            label: "email setting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.EMAIL_SETTING}`,
          name: "email setting domain",
          component: EmailSetting,
          meta: {
            title: "メール設定",
            label: "email setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DESIGN_SETTING}`,
          name: "design setting",
          component: DesignSetting,
          meta: {
            title: "デザイン設定",
            label: "design setting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DESIGN_SETTING}`,
          name: "design setting domain",
          component: DesignSetting,
          meta: {
            title: "デザイン設定",
            label: "design setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY_NEW}`,
          name: "list category new",
          component: ListCategoryNew,
          meta: {
            title: "ニュースカテゴリ管理",
            label: "list category new",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY_NEW}`,
          name: "list category new domain",
          component: ListCategoryNew,
          meta: {
            title: "ニュースカテゴリ管理",
            label: "list category new domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY_NEW}/${Routes.CREATE}`,
          name: "create category new",
          component: EditCategoryNew,
          meta: {
            title: "カテゴリ登録",
            label: "create category new",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY_NEW}/${Routes.CREATE}`,
          name: "create category new domain",
          component: EditCategoryNew,
          meta: {
            title: "カテゴリ登録",
            label: "create category new domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CATEGORY_NEW}/${Routes.EDIT}/:id`,
          name: "edit category new",
          component: EditCategoryNew,
          meta: {
            title: "カテゴリ登録",
            label: "edit category new",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CATEGORY_NEW}/${Routes.EDIT}/:id`,
          name: "edit category new domain",
          component: EditCategoryNew,
          meta: {
            title: "カテゴリ登録",
            label: "edit category new domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list new admin domain",
              parent: "ニュース管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.STORE}`,
          name: "store setting",
          component: SettingStore,
          meta: {
            title: "ストア設定",
            label: "store setting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.STORE}`,
          name: "store setting domain",
          component: SettingStore,
          meta: {
            title: "ストア設定",
            label: "store setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FIXED_PAGE}`,
          name: "listFixedPage",
          component: ListFixedPage,
          meta: {
            title: "単発ページ",
            label: "listFixedPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FIXED_PAGE}`,
          name: "listFixedPage domain",
          component: ListFixedPage,
          meta: {
            title: "単発ページ",
            label: "listFixedPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate domain",
              parent: "作成メニュー",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SURVEY}`,
          name: "list survey",
          component: ListSurvey,
          meta: {
            title: "質問管理",
            label: "list survey",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SURVEY}`,
          name: "list survey domain",
          component: ListSurvey,
          meta: {
            title: "質問管理",
            label: "list survey domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SURVEY}/${Routes.CREATE}`,
          name: "create survey",
          component: CreateSurvey,
          meta: {
            label: "create survey",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SURVEY}/${Routes.CREATE}`,
          name: "create survey domain",
          component: CreateSurvey,
          meta: {
            label: "create survey domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SURVEY}/${Routes.EDIT}/:id`,
          name: "edit survey",
          component: EditSurvey,
          meta: {
            label: "edit survey",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SURVEY}/${Routes.EDIT}/:id`,
          name: "edit survey domain",
          component: EditSurvey,
          meta: {
            label: "edit survey domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.INQUIRY}`,
          name: "list inquiry",
          component: ListInquiry,
          meta: {
            title: "お問い合わせ管理",
            label: "list inquiry",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.INQUIRY}`,
          name: "list inquiry domain",
          component: ListInquiry,
          meta: {
            title: "お問い合わせ管理",
            label: "list inquiry domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.REALTIME_ACTIVITY}`,
          name: "list realtime activity",
          component: ListRealtimeActivity,
          meta: {
            title: "アクセス中のユーザー",
            label: "list realtime activity",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.REALTIME_ACTIVITY}`,
          name: "list realtime activity domain",
          component: ListRealtimeActivity,
          meta: {
            title: "アクセス中のユーザー",
            label: "list realtime activity domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.INQUIRY}/${Routes.DETAIL}/:index/:page`,
          name: "detail inquiry",
          component: DetailInquiry,
          meta: {
            title: "確認",
            label: "detail inquiry",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX ",
            },
            breadcrumb2: {
              url: "list inquiry",
              parent: "お問い合わせ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.INQUIRY}/${Routes.DETAIL}/:index/:page`,
          name: "detail inquiry domain",
          component: DetailInquiry,
          meta: {
            title: "確認",
            label: "detail inquiry domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX ",
            },
            breadcrumb2: {
              url: "list inquiry domain",
              parent: "お問い合わせ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.INQUIRY}/${Routes.CREATE}`,
          name: "create inquiry",
          component: CreateInquiry,
          meta: {
            title: "作成",
            label: "create inquiry",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX ",
            },
            breadcrumb2: {
              url: "list inquiry",
              parent: "お問い合わせ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.INQUIRY}/${Routes.CREATE}`,
          name: "create inquiry domain",
          component: CreateInquiry,
          meta: {
            title: "作成",
            label: "create inquiry domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム ",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX ",
            },
            breadcrumb2: {
              url: "list inquiry domain",
              parent: "お問い合わせ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.CREATE}`,
          name: "createFixedPage",
          component: CreateFixedPage,
          meta: {
            title: "単発ページ作成",
            label: "createFixedPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate",
              parent: "作成メニュー ",
            },
            breadcrumb2: {
              url: "listFixedPage",
              parent: "単発ページ",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.CREATE}`,
          name: "createFixedPage domain",
          component: CreateFixedPage,
          meta: {
            title: "単発ページ作成",
            label: "createFixedPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate domain",
              parent: "作成メニュー ",
            },
            breadcrumb2: {
              url: "listFixedPage domain",
              parent: "単発ページ",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.EDIT}/:id`,
          name: "editFixedPage",
          component: CreateFixedPage,
          meta: {
            title: "単発ページ編集",
            label: "editFixedPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate",
              parent: "作成メニュー ",
            },
            breadcrumb2: {
              url: "listFixedPage",
              parent: "単発ページ",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.EDIT}/:id`,
          name: "editFixedPage domain",
          component: CreateFixedPage,
          meta: {
            title: "単発ページ編集",
            label: "editFixedPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate domain",
              parent: "作成メニュー ",
            },
            breadcrumb2: {
              url: "listFixedPage domain",
              parent: "単発ページ",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_POP_UP}`,
          name: "Timing Popup Management",
          component: TimingPopupManagement,
          meta: {
            title: "タイミングポップアップ管理",
            label: "Timing Popup Management",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_POP_UP}`,
          name: "Timing Popup Management domain",
          component: TimingPopupManagement,
          meta: {
            title: "タイミングポップアップ管理",
            label: "Timing Popup Management domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_POP_UP}/${Routes.CREATE}`,
          name: "Create Timing Popup",
          component: CreateTimingPopup,
          meta: {
            title: "新規作成",
            label: "Create Timing Popup",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Popup Management",
              parent: "タイミングポップアップ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_POP_UP}/${Routes.CREATE}`,
          name: "Create Timing Popup domain",
          component: CreateTimingPopup,
          meta: {
            title: "新規作成",
            label: "Create Timing Popup domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Popup Management domain",
              parent: "タイミングポップアップ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_POP_UP}/${Routes.EDIT}/:id`,
          name: "Edit Timing Popup",
          component: CreateTimingPopup,
          meta: {
            title: "編集",
            label: "Edit Timing Popup",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Popup Management",
              parent: "タイミングポップアップ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_POP_UP}/${Routes.EDIT}/:id`,
          name: "Edit Timing Popup domain",
          component: CreateTimingPopup,
          meta: {
            title: "編集",
            label: "Edit Timing Popup domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Popup Management domain",
              parent: "タイミングポップアップ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}`,
          name: "globalSetting",
          component: GlobalSetting,
          meta: {
            title: "全体設定",
            label: "globalSetting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}`,
          name: "globalSetting domain",
          component: GlobalSetting,
          meta: {
            title: "全体設定",
            label: "globalSetting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.PAYMENT}`,
          name: "paymentSetting",
          component: PaymentSetting,
          meta: {
            title: "決済情報登録",
            label: "paymentSetting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.PAYMENT}`,
          name: "paymentSetting domain",
          component: PaymentSetting,
          meta: {
            title: "決済情報登録",
            label: "paymentSetting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.SALE}`,
          name: "Sales Setting",
          component: SalesSetting,
          meta: {
            title: "販売情報設定",
            label: "Sales Setting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.SALE}`,
          name: "Sales Setting domain",
          component: SalesSetting,
          meta: {
            title: "販売情報設定",
            label: "Sales Setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_MAIL}`,
          name: "Timing Mail",
          component: TimingMail,
          meta: {
            title: "簡易メール配信管理",
            label: "Timing Mail",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "DashboardAdminCreate",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_MAIL}`,
          name: "Timing Mail domain",
          component: TimingMail,
          meta: {
            title: "簡易メール配信管理",
            label: "Timing Mail domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "DashboardAdminCreate domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.URL}`,
          name: "URLManager",
          component: URLManager,
          meta: {
            title: "URL管理",
            label: "URLManager",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.URL}`,
          name: "URLManager domain",
          component: URLManager,
          meta: {
            title: "URL管理",
            label: "URLManager domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.URL}/${Routes.CREATE}`,
          name: "CreateURL",
          component: CreateURL,
          meta: {
            title: "URL作成",
            label: "CreateURL",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "URLManager",
              parent: "URL管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.URL}/${Routes.CREATE}`,
          name: "CreateURL domain",
          component: CreateURL,
          meta: {
            title: "URL作成",
            label: "CreateURL domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "URLManager domain",
              parent: "URL管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.URL}/${Routes.EDIT}/:id`,
          name: "editURL",
          component: CreateURL,
          meta: {
            title: "URL 編集",
            label: "editURL",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "URLManager",
              parent: "URL管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.URL}/${Routes.EDIT}/:id`,
          name: "editURL domain",
          component: CreateURL,
          meta: {
            title: "URL 編集",
            label: "editURL domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "URLManager domain",
              parent: "URL管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.GENERAL}`,
          name: "General Setting",
          component: GeneralSetting,
          meta: {
            title: "一般設定",
            label: "General Setting",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.GENERAL}`,
          name: "General Setting domain",
          component: GeneralSetting,
          meta: {
            title: "一般設定",
            label: "General Setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_MAIL}/${Routes.CREATE}`,
          name: "Create TimingMail",
          component: CreateTimingMail,
          meta: {
            title: "新規作成",
            label: "Create TimingMail",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Mail",
              parent: "簡易メール配信一覧",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_MAIL}/${Routes.CREATE}`,
          name: "Create TimingMail domain",
          component: CreateTimingMail,
          meta: {
            title: "新規作成",
            label: "Create TimingMail domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Timing Mail domain",
              parent: "簡易メール配信一覧",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TIMING_MAIL}/${Routes.EDIT}/:id`,
          name: "Edit Timing Mail",
          component: EditTimingMail,
          meta: {
            title: "編集",
            label: "Edit Timing Mail",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "Timing Mail",
              parent: "簡易メール配信一覧",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TIMING_MAIL}/${Routes.EDIT}/:id`,
          name: "Edit Timing Mail domain",
          component: EditTimingMail,
          meta: {
            title: "編集",
            label: "Edit Timing Mail domain",
            requiresAuth: true,
            userType: [0, 1],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "Timing Mail domain",
              parent: "簡易メール配信一覧",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SETTING}/${Routes.SNS}`,
          name: "SNS Setting",
          component: SNSSetting,
          meta: {
            title: "SNS設定",
            label: "SNS Setting",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SETTING}/${Routes.SNS}`,
          name: "SNS Setting domain",
          component: SNSSetting,
          meta: {
            title: "SNS設定",
            label: "SNS Setting domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.MENU}`,
          name: "Menu SaleManagerment",
          component: MenuSaleManagement,
          meta: {
            title: "売上管理",
            label: "Menu SaleManagerment",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SALES_MANAGEMENT}/${Routes.MENU}`,
          name: "Menu SaleManagerment domain",
          component: MenuSaleManagement,
          meta: {
            title: "売上管理",
            label: "Menu SaleManagerment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.NOTE}`,
          name: "List Note",
          component: ListNote,
          meta: {
            title: "ノート管理",
            label: "List Note",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.NOTE}`,
          name: "List Note domain",
          component: ListNote,
          meta: {
            title: "ノート管理",
            label: "List Note domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CART}`,
          name: "List Cart",
          component: ListCart,
          meta: {
            title: "カート利用中のユーザー一覧",
            label: "List Cart",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CART}`,
          name: "List Cart domain",
          component: ListCart,
          meta: {
            title: "カート利用中のユーザー一覧",
            label: "List Cart domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CREATE}`,
          name: "Content Change",
          component: ContentChange,
          meta: {
            title: "コンテンツ作成",
            label: "Content Change",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.CREATE}`,
          name: "Content Change domain",
          component: ContentChange,
          meta: {
            title: "コンテンツ作成",
            label: "Content Change domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content domain",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.EDIT}/:id`,
          name: "Edit Content Change",
          component: ContentChange,
          meta: {
            title: "編集",
            label: "Edit Content Change",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.EDIT}/:id`,
          name: "Edit Content Change domain",
          component: ContentChange,
          meta: {
            title: "編集",
            label: "Edit Content Change domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content domain",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.SALE}/${Routes.CREATE}/`,
          name: "Content Sale Create",
          component: ContentSale,
          meta: {
            title: "ストア編集",
            label: "Content Sale Create",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.SALE}/${Routes.CREATE}/`,
          name: "Content Sale Create domain",
          component: ContentSale,
          meta: {
            title: "ストア編集",
            label: "Content Sale Create domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content domain",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.SALE}/${Routes.EDIT}/:id`,
          name: "Content Sale",
          component: ContentSale,
          meta: {
            title: "ストア編集",
            label: "Content Sale",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.SALE}/${Routes.EDIT}/:id`,
          name: "Content Sale domain",
          component: ContentSale,
          meta: {
            title: "編集",
            label: "Content Sale domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content domain",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.URL}/:id`,
          name: "Content Url",
          component: ContentUrl,
          meta: {
            title: "URL管理",
            label: "Content Url",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CONTENT}/${Routes.URL}/:id`,
          name: "Content Url domain",
          component: ContentUrl,
          meta: {
            title: "URL管理",
            label: "Content Url domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "list content domain",
              parent: "コンテンツ管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TRIGGER}`,
          name: "List Trigger",
          component: ListTrigger,
          meta: {
            title: "トリガー管理",
            label: "List Trigger",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TRIGGER}`,
          name: "List Trigger domain",
          component: ListTrigger,
          meta: {
            title: "トリガー管理",
            label: "List Trigger domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.MAIL_MEGA}`,
          name: "List Mail Mega",
          component: MailMega,
          meta: {
            title: "登録メルマガ管理",
            label: "List Mail Mega",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.MAIL_MEGA}`,
          name: "List Mail Mega domain",
          component: MailMega,
          meta: {
            title: "登録メルマガ管理",
            label: "List Mail Mega domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.MAIL_MEGA}/${Routes.CREATE}`,
          name: "Create Mail Mega",
          component: CreateMailMega,
          meta: {
            title: "新規作成",
            label: "Create Mail Mega",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "List Mail Mega",
              parent: "登録メルマガ個別設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.MAIL_MEGA}/${Routes.CREATE}`,
          name: "Create Mail Mega domain",
          component: CreateMailMega,
          meta: {
            title: "新規作成",
            label: "Create Mail Mega domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "List Mail Mega domain",
              parent: "登録メルマガ個別設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.MAIL_MEGA}/${Routes.EDIT}/:id`,
          name: "Edit Mail Mega",
          component: CreateMailMega,
          meta: {
            title: "編集",
            label: "Edit Mail Mega",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Mail Mega",
              parent: "登録メルマガ個別設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.MAIL_MEGA}/${Routes.EDIT}/:id`,
          name: "Edit Mail Mega domain",
          component: CreateMailMega,
          meta: {
            title: "編集",
            label: "Edit Mail Mega domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Mail Mega domain",
              parent: "登録メルマガ個別設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TRIGGER}/${Routes.CREATE}`,
          name: "Create Trigger",
          component: CreateTrigger,
          meta: {
            title: "新規作成",
            label: "Create Trigger",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Trigger",
              parent: "トリガー管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TRIGGER}/${Routes.CREATE}`,
          name: "Create Trigger domain",
          component: CreateTrigger,
          meta: {
            title: "新規作成",
            label: "Create Trigger domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Trigger domain",
              parent: "トリガー管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.TRIGGER}/${Routes.EDIT}/:id`,
          name: "Edit Trigger",
          component: CreateTrigger,
          meta: {
            title: "編集",
            label: "Edit Trigger",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "List Trigger",
              parent: "トリガー管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.TRIGGER}/${Routes.EDIT}/:id`,
          name: "Edit Trigger domain",
          component: CreateTrigger,
          meta: {
            title: "編集",
            label: "Edit Trigger domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "List Trigger domain",
              parent: "トリガー管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CHAPTER}`,
          name: "List Chapter Managerment",
          component: ChapterManagerment,
          meta: {
            title: "章設定",
            label: "List Chapter Managerment",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CHAPTER}`,
          name: "List Chapter Managerment domain",
          component: ChapterManagerment,
          meta: {
            title: "章設定",
            label: "List Chapter Managerment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CHAPTER}/${Routes.CREATE}`,
          name: "Create Chapter Managerment",
          component: CreateChapterManagerment,
          meta: {
            title: "新規作成",
            label: "Create Chapter Managerment",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
            breadcrumb2: {
              url: "List Chapter Managerment",
              parent: "章設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CHAPTER}/${Routes.CREATE}`,
          name: "Create Chapter Managerment domain",
          component: CreateChapterManagerment,
          meta: {
            title: "新規作成",
            label: "Create Chapter Managerment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
            breadcrumb2: {
              url: "List Chapter Managerment domain",
              parent: "章設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.CHAPTER}/${Routes.EDIT}/:id`,
          name: "Edit Chapter Managerment",
          component: CreateChapterManagerment,
          meta: {
            title: "編集",
            label: "Edit Chapter Managerment",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit",
              parent: "編集メニュー",
            },
            breadcrumb2: {
              url: "List Chapter Managerment",
              parent: "章設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.CHAPTER}/${Routes.EDIT}/:id`,
          name: "Edit Chapter Managerment domain",
          component: CreateChapterManagerment,
          meta: {
            title: "編集",
            label: "Edit Chapter Managerment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "DashboardAdminEdit domain",
              parent: "編集メニュー",
            },
            breadcrumb2: {
              url: "List Chapter Managerment domain",
              parent: "章設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGES}/404`,
          name: "page404admin",
          component: Page404Admin,
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PAGES}/404`,
          name: "page404admin domain",
          component: Page404Admin,
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT_DEFINITION}`,
          name: "List Point Definition domain",
          component: Purchasepoint,
          meta: {
            title: "ポイント定義",
            label: "List Point Definition domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.POINT_DEFINITION}`,
          name: "Create Point Definition",
          component: CreatePurchasepoint,
          meta: {
            title: "ポイント定義",
            label: "Create Point Definition",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT_DEFINITION}/${Routes.CREATE}`,
          name: "Create Point Definition domain",
          component: CreatePurchasepoint,
          meta: {
            title: "新規作成",
            label: "Create Point Definition domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Point Definition domain",
              parent: "ポイント定義",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.POINT_DEFINITION}/${Routes.EDIT}/:id`,
          name: "Edit Point Definition",
          component: CreatePurchasepoint,
          meta: {
            title: "編集",
            label: "Edit Point Definition",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Point Definition",
              parent: "ポイント定義",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.POINT_DEFINITION}/${Routes.EDIT}/:id`,
          name: "Edit Point Definition domain",
          component: CreatePurchasepoint,
          meta: {
            title: "編集",
            label: "Edit Point Definition domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List Point Definition domain",
              parent: "ポイント定義",
            },
            breadcrumb3: {
              url: "DashboardAdminSetting domain",
              parent: "全体設定",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.QUESTION}`,
          name: "Chat Admin",
          component: ListChatAdmin,
          meta: {
            title: "質問機能",
            label: "Chat Admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.QUESTION}`,
          name: "Chat Admin domain",
          component: ListChatAdmin,
          meta: {
            title: "質問機能",
            label: "Chat Admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.QUESTION}/${Routes.USER}/:userId`,
          name: "List chat user",
          component: ListChatUser,
          meta: {
            title: "質問機能",
            label: "List chat user",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー管理",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.QUESTION}/${Routes.USER}/:userId`,
          name: "List chat user domain",
          component: ListChatUser,
          meta: {
            title: "質問機能",
            label: "List chat user domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
            breadcrumb2: {
              url: "list user",
              parent: "ユーザー管理",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.QUESTION}/:id`,
          name: "Detail Chat Admin",
          component: DetailChatAdmin,
          meta: {
            title: "編集",
            label: "Detail Chat Admin",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Chat Admin",
              parent: "質問機能",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.QUESTION}/:id`,
          name: "Detail Chat Admin domain",
          component: DetailChatAdmin,
          meta: {
            title: "編集",
            label: "Detail Chat Admin domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "Chat Admin domain",
              parent: "質問機能",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.DISPLAY_CONTENT}`,
          name: "Content display Confirmation",
          component: ContentdisplayConfirmation,
          meta: {
            label: "Content display Confirmation",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.DISPLAY_CONTENT}`,
          name: "Content display Confirmation domain",
          component: ContentdisplayConfirmation,
          meta: {
            label: "Content display Confirmation domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.COUPON}/${Routes.CREATE}`,
        //   name: "Create coupon",
        //   component: CreateCoupon,
        //   meta: {
        //     label: "Create coupon",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin",
        //       parent: "ホーム",
        //     },
        //     breadcrumb2: {
        //       url: "List coupon",
        //       parent: "クーポン",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        // {
        //   path: `/${Routes.ADMIN}/${Routes.COUPON}/${Routes.CREATE}`,
        //   name: "Create coupon domain",
        //   component: CreateCoupon,
        //   meta: {
        //     label: "Create coupon domain",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin domain",
        //       parent: "ホーム",
        //     },
        //     breadcrumb2: {
        //       url: "List coupon domain",
        //       parent: "クーポン",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox domain",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.COUPON}/${Routes.EDIT}/:id`,
        //   name: "Edit coupon",
        //   component: CreateCoupon,
        //   meta: {
        //     label: "Edit coupon",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin",
        //       parent: "ホーム",
        //     },
        //     breadcrumb2: {
        //       url: "List coupon",
        //       parent: "クーポン",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        // {
        //   path: `/${Routes.ADMIN}/${Routes.COUPON}/${Routes.EDIT}/:id`,
        //   name: "Edit coupon domain",
        //   component: CreateCoupon,
        //   meta: {
        //     label: "Edit coupon domain",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin domain",
        //       parent: "ホーム",
        //     },
        //     breadcrumb2: {
        //       url: "List coupon domain",
        //       parent: "クーポン",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox domain",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.COUPON}`,
        //   name: "List coupon",
        //   component: ListCoupon,
        //   meta: {
        //     label: "List coupon",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin",
        //       parent: "ホーム",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        // {
        //   path: `/${Routes.ADMIN}/${Routes.COUPON}`,
        //   name: "List coupon domain",
        //   component: ListCoupon,
        //   meta: {
        //     label: "List coupon domain",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3],
        //     breadcrumb: {
        //       url: "Dashboard Admin domain",
        //       parent: "ホーム",
        //     },
        //     breadcrumb3: {
        //       url: "DashboardAdminBox domain",
        //       parent: "ツールBOX",
        //     },
        //   },
        // },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SENTENCE}/${Routes.CREATE}`,
          name: "Create sentence",
          component: CreateSentence,
          meta: {
            label: "Create sentence",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List sentence",
              parent: "文章定義一覧",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SENTENCE}/${Routes.CREATE}`,
          name: "Create sentence domain",
          component: CreateSentence,
          meta: {
            label: "Create sentence domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List sentence domain",
              parent: "文章定義一覧",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SENTENCE}/${Routes.EDIT}/:id`,
          name: "Edit sentence",
          component: CreateSentence,
          meta: {
            label: "Edit sentence",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List sentence",
              parent: "文章定義一覧",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SENTENCE}/${Routes.EDIT}/:id`,
          name: "Edit sentence domain",
          component: CreateSentence,
          meta: {
            label: "Edit sentence domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb2: {
              url: "List sentence domain",
              parent: "文章定義一覧",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.SENTENCE}`,
          name: "List sentence",
          component: ListSentence,
          meta: {
            label: "List sentence",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.SENTENCE}`,
          name: "List sentence domain",
          component: ListSentence,
          meta: {
            label: "List sentence domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminBox domain",
              parent: "ツールBOX",
            },
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.MY_PROFILE}`,
          name: "Create My Profile",
          component: CreateMyProfile,
          meta: {
            title: "マイページ作成",
            label: "Create My Profile",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate",
              parent: "作成メニュー ",
            },
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.MY_PROFILE}`,
          name: "Create My Profile domain",
          component: CreateMyProfile,
          meta: {
            title: "マイページ作成",
            label: "Create My Profile domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
            breadcrumb: {
              url: "Dashboard Admin domain",
              parent: "ホーム",
            },
            breadcrumb3: {
              url: "DashboardAdminCreate domain",
              parent: "作成メニュー ",
            },
          },
        },
      ],
    },
    {
      path: ``,
      redirect: ``,
      component: LayoutHome,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId`,
          name: "library",
          component: Library,
          meta: {
            title: "ホーム",
            label: "library",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: ``,
          name: "library domain",
          component: Library,
          meta: {
            title: "ホーム",
            label: "library domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.LIBRARY}/:keyword`,
        //   name: "library search",
        //   component: Library,
        //   meta: {
        //     label: "library search",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3, 4],
        //   },
        // },
        // {
        //   path: `/${Routes.SHOP}/:shopId/${Routes.CONTENT}/:id`,
        //   name: "default content",
        //   component: Content,
        //   meta: {
        //     label: "default content",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3, 4],
        //   },
        // },
        // {
        //   path: `/${Routes.CONTENT}/:id`,
        //   name: "default content domain",
        //   component: Content,
        //   meta: {
        //     label: "default content domain",
        //     requiresAuth: true,
        //     userType: [0, 1, 2, 3, 4],
        //   },
        // },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CONTENT}/${Routes.PAGE}/:idContent/:idPage`,
          name: "default page",
          component: Page,
          meta: {
            label: "default page",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CONTENT}/${Routes.PAGE}/:idContent/:idPage`,
          name: "default page domain",
          component: Page,
          meta: {
            label: "default page domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.STORE}`,
          name: "store",
          component: Store,
          meta: {
            title: "店舗",
            label: "store",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.STORE}`,
          name: "store domain",
          component: Store,
          meta: {
            title: "店舗",
            label: "store domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CART}`,
          name: "default cart",
          component: Cart,
          meta: {
            title: "カート",
            label: "default cart",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CART}`,
          name: "default cart domain",
          component: Cart,
          meta: {
            title: "カート",
            label: "default cart domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },

        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CHECKOUT}`,
          name: "checkout",
          component: Checkout,
          meta: {
            label: "checkout",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CHECKOUT}`,
          name: "checkout domain",
          component: Checkout,
          meta: {
            label: "checkout domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.PAYMENT}/:id`,
          name: "payment",
          component: Payment,
          meta: {
            title: "決済",
            label: "payment",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.PAYMENT}/p/:id`,
          name: "paymentBuy",
          component: Payment,
          meta: {
            title: "決済",
            label: "paymentBuy",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.PAYMENT}/:id`,
          name: "payment domain",
          component: Payment,
          meta: {
            title: "決済",
            label: "payment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.PAYMENT}/p/:id`,
          name: "paymentBuy domain",
          component: Payment,
          meta: {
            title: "決済",
            label: "paymentBuy domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CONFIRM_PAYMENT}/:id`,
          name: "confirm_payment",
          component: ConfirmPayment,
          meta: {
            title: "決済確認",
            label: "confirm_payment",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CONFIRM_PAYMENT}/:id`,
          name: "confirm_payment domain",
          component: ConfirmPayment,
          meta: {
            title: "決済確認",
            label: "confirm_payment domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.EXTERNAL_PAYMENT_CHECK}/:identifier`,
          name: "external_payment_check",
          component: ExternalPaymentCheck,
          meta: {
            title: "外部決済確認",
            label: "external_payment_check",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.EXTERNAL_PAYMENT_CHECK}/:identifier`,
          name: "external_payment_check domain",
          component: ExternalPaymentCheck,
          meta: {
            title: "外部決済完了",
            label: "external_payment_check domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.PAGE}/${Routes.THANK}`,
          name: "page_thank",
          component: PageThank,
          meta: {
            title: "サンキューページ",
            label: "page_thank",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.PAGE}/${Routes.THANK}`,
          name: "page_thank domain",
          component: PageThank,
          meta: {
            title: "サンキューページ",
            label: "page_thank domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.AUTHOR}`,
          name: "ListAuthorStore",
          component: Author,
          meta: {
            title: "著者一覧",
            label: "ListAuthorStore",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.AUTHOR}`,
          name: "ListAuthorStore domain",
          component: Author,
          meta: {
            title: "著者リスト",
            label: "ListAuthorStore domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.STORE}/:id`,
          name: "content detail",
          component: ContentDetail,
          meta: {
            title: "コンテンツ詳細（店舗）",
            label: "content detail",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.STORE}/:id`,
          name: "content detail domain",
          component: ContentDetail,
          meta: {
            title: "コンテンツ詳細（店舗）",
            label: "content detail domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.AUTHOR}/:id`,
          name: "author detail",
          component: AuthorDetail,
          meta: {
            title: "著者詳細",
            label: "author detail",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.AUTHOR}/:id`,
          name: "author detail domain",
          component: AuthorDetail,
          meta: {
            label: "author detail domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.STOREAUTHORS}`,
          name: "store author",
          component: StoreAuthor,
          meta: {
            label: "store author",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.STOREAUTHORS}`,
          name: "store author domain",
          component: StoreAuthor,
          meta: {
            label: "store author domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.POINT}/${Routes.HISTORY}`,
          name: "history point",
          component: HistoryPoint,
          meta: {
            title: "ポイント購入履歴",
            label: "history point",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.POINT}/${Routes.HISTORY}`,
          name: "history point domain",
          component: HistoryPoint,
          meta: {
            title: "ポイント購入履歴",
            label: "history point domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CONTENT}/:idContent`,
          name: "DetailContentLibrary",
          component: DetailContentLibrary,
          meta: {
            title: "コンテンツ詳細（ライブラリ）",
            label: "DetailContentLibrary",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CONTENT}/:idContent`,
          name: "DetailContentLibrary domain",
          component: DetailContentLibrary,
          meta: {
            title: "コンテンツ詳細（ライブラリ）",
            label: "DetailContentLibrary domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CONTENT}/:idContent/${Routes.PAGE}=:idPage`,
          name: "DetailPageLibrary",
          component: DetailPageLibrary,
          meta: {
            title: "コンテンツ内ページ詳細（ライブラリ）",
            label: "DetailPageLibrary",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CONTENT}/:idContent/${Routes.PAGE}=:idPage`,
          name: "DetailPageLibrary domain",
          component: DetailPageLibrary,
          meta: {
            title: "コンテンツ内ページ詳細（ライブラリ）",
            label: "DetailPageLibrary domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PREVIEW}/${Routes.PAGE}`,
          name: "ReviewPage",
          component: ReviewPage,
          meta: {
            label: "ReviewPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.PREVIEW}/${Routes.PAGE}`,
          name: "ReviewPage domain",
          component: ReviewPage,
          meta: {
            label: "ReviewPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.MY_PROFILE}`,
          name: "my profile",
          component: MyProfile,
          meta: {
            title: "マイページ",
            label: "my profile",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.MY_PROFILE}`,
          name: "my profile domain",
          component: MyProfile,
          meta: {
            title: "マイページ",
            label: "my profile domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.NEWS}`,
          name: "list new user",
          component: ListNewUser,
          meta: {
            title: "ニュース一覧",
            label: "list new user",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.NEWS}`,
          name: "list new user domain",
          component: ListNewUser,
          meta: {
            title: "ニュース一覧",
            label: "list new user domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.NEWS}/${Routes.DETAIL}/:id`,
          name: "detail new user",
          component: DetailNewUser,
          meta: {
            label: "detail new user",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.NEWS}/${Routes.DETAIL}/:id`,
          name: "detail new user domain",
          component: DetailNewUser,
          meta: {
            label: "detail new user domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.INQUIRY}`,
          name: "create inquiry user",
          component: CreateInquiryUser,
          meta: {
            title: "問い合わせ",
            label: "create inquiry user",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.INQUIRY}`,
          name: "create inquiry user domain",
          component: CreateInquiryUser,
          meta: {
            title: "問い合わせ",
            label: "create inquiry user domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.NOTE_CONTENT}`,
          name: "list note content",
          component: ListNoteContent,
          meta: {
            title: "ノート一覧",
            label: "list note content",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.NOTE_CONTENT}`,
          name: "list note content domain",
          component: ListNoteContent,
          meta: {
            title: "ノート一覧",
            label: "list note content domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.PREVIEW}`,
          name: "previewPage",
          component: PreviewPage,
          meta: {
            label: "previewPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.PREVIEW}`,
          name: "previewPage domain",
          component: PreviewPage,
          meta: {
            label: "previewPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.FIXED_PAGE}/:id`,
          name: "staticPage",
          component: PageFixed,
          meta: {
            title: "固定ページ詳細",
            label: "staticPage",
            guest: true,
          },
        },
        {
          path: `/${Routes.FIXED_PAGE}/:id`,
          name: "staticPage domain",
          component: PageFixed,
          meta: {
            title: "固定ページ詳細",
            label: "staticPage domain",
            guest: true,
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.PREVIEW_COLUMN}`,
          name: "previewColumnPage",
          component: PreviewColumnPage,
          meta: {
            label: "previewColumnPage",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FIXED_PAGE}/${Routes.PREVIEW_COLUMN}`,
          name: "previewColumnPage domain",
          component: PreviewColumnPage,
          meta: {
            label: "previewColumnPage domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.FIXED_PAGE_COLUMN}/:id`,
          name: "pageColumn",
          component: PageColumn,
        },
        {
          path: `/${Routes.FIXED_PAGE_COLUMN}/:id`,
          name: "pageColumn domain",
          component: PageColumn,
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
          name: "page404",
          component: Page404,
          meta: {
            label: "page404",
            requiresAuth: true,
            userType: [0, 4],
          },
        },
        {
          path: `/${Routes.PAGES}/404`,
          name: "page404 domain",
          component: Page404,
          meta: {
            label: "page404 domain",
            requiresAuth: true,
            userType: [0, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.DEL_ACC}`,
          name: "DeleteAccount",
          component: DeleteAccount,
          meta: {
            label: "Delete Account",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.DEL_ACC}`,
          name: "DeleteAccount domain",
          component: DeleteAccount,
          meta: {
            label: "Delete Account domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.HELP_PAGE}`,
          name: "help page",
          component: HelpPage,
          meta: {
            label: "help page",
            requiresAuth: true,
            userType: [4],
          },
        },
        {
          path: `/${Routes.HELP_PAGE}`,
          name: "help page domain",
          component: HelpPage,
          meta: {
            label: "help page domain",
            requiresAuth: true,
            userType: [4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.CHAT_CONTENT}`,
          name: "List Chat Content",
          component: ListChatContent,
          meta: {
            label: "List Chat Content",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.CHAT_CONTENT}`,
          name: "List Chat Content domain",
          component: ListChatContent,
          meta: {
            label: "List Chat Content domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.NOTICE}`,
          name: "page notice",
          component: PageNotice,
          meta: {
            label: "page notice",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.NOTICE}`,
          name: "page notice domain",
          component: PageNotice,
          meta: {
            label: "page notice domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
      ],
    },
    {
      path: `/`,
      redirect: `/`,
      name: "login shop domain",
      component: LayoutAuth,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.LOGIN}`,
          name: "login shop",
          component: Login,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `/${Routes.LOGIN}`,
          name: "login shop domain",
          component: Login,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.LOGIN}`,
          name: "login admin",
          component: LoginAdmin,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.LOGIN}`,
          name: "login admin domain",
          component: LoginAdmin,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `${Routes.ADMIN}/${Routes.LOGIN}`,
          name: "login admin domain",
          component: LoginAdmin,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.LOGIN}`,
          name: "login cdea",
          component: LoginCDEA,
          meta: {
            title: "ログイン",
          },
        },
        {
          path: `/${Routes.CDEA_ADMIN}/${Routes.FORGOTPASSWORD}`,
          name: "forgot password cdea",
          component: ForgotPasswordCDEA,
          meta: {
            title: "パスワードを忘れた方",
          },
        },
        {
          path: `${Routes.SHOP}/:shopId/${Routes.SIGNUP}`,
          name: "create member",
          component: CreateMember,
        },
        {
          path: `/${Routes.SIGNUP}`,
          name: "create member domain",
          component: CreateMember,
        },
        {
          path: `${Routes.CREATE}/${Routes.OWNER}`,
          name: "create owner default",
          component: CreateOwnerDefault,
        },
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.FORGOTPASSWORD}`,
          name: "forgot password admin",
          component: ForgotPasswordAdmin,
          meta: {
            title: "パスワードを忘れた方",
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.FORGOTPASSWORD}`,
          name: "forgot password admin domain",
          component: ForgotPasswordAdmin,
          meta: {
            title: "パスワードを忘れた方",
          },
        },
        {
          path: `${Routes.SHOP}/:shopId/${Routes.FORGOTPASSWORD}`,
          name: "forgot password",
          component: ForgotPassword,
          meta: {
            title: "パスワードを忘れた方",
          },
        },
        {
          path: `${Routes.FORGOTPASSWORD}`,
          name: "forgot password domain",
          component: ForgotPassword,
          meta: {
            title: "パスワードを忘れた方",
          },
        },
        {
          path: `${Routes.CHANGEPASSWORD}/:token`,
          name: "change password",
          component: ChangePassword,
        },
        {
          path: `/${Routes.FACEBOOK}`,
          name: "login facebook",
          component: FacebookLogin,
        },
        {
          path: `/${Routes.TWITTER}`,
          name: "login twitter",
          component: TwitterLogin,
        },
        {
          path: `${Routes.LINE}`,
          name: "login line",
          component: LineLogin,
        },
        {
          path: `${Routes.AMAZON}`,
          name: "login amazon",
          component: AmazonLogin,
        },
        {
          path: `${Routes.ACTIVEMAIL}`,
          name: "active mail",
          component: ActiveMail,
        },
        {
          path: `${Routes.SHOP}/:shopId/${Routes.BUY}/p/:idContent`,
          name: "Before Login",
          component: BeforeLogin,
          meta: {
            label: "Before Login",
            guest: true,
          },
        },
        {
          path: `/${Routes.BUY}/p/:idContent`,
          name: "Before Login domain",
          component: BeforeLogin,
          meta: {
            label: "Before Login domain",
            guest: true,
          },
        },
        {
          path: `${Routes.SHOP}/:shopId/${Routes.SIGNUP}/p/:idContent`,
          name: "Before SignUp",
          component: BeforeSignUp,
        },
        {
          path: `/${Routes.SIGNUP}/p/:idContent`,
          name: "Before SignUp domain",
          component: BeforeSignUp,
        },
        // 外部決済Thanksページからの遷移先
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.EXTERNAL_PAYMENT_REDIRECT}/:identifier`,
          name: "external_payment_redirect",
          component: ExternalPaymentRedirect,
          meta: {
            title: "外部決済手続き中",
            label: "external_payment_thanks",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        {
          path: `/${Routes.EXTERNAL_PAYMENT_REDIRECT}/:identifier`,
          name: "external_payment_redirect domain",
          component: ExternalPaymentRedirect,
          meta: {
            title: "外部決済手続き中",
            label: "external_payment_thanks domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3, 4],
          },
        },
        // 外部決済メールからの承認
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.EXTERNAL_PAYMENT_APPROVAL}/:token`,
          name: "External payment approval",
          component: ExternalPaymentApproval,
          meta: {
            title: "外部決済手動承認",
            label: "External payment approval",
            guest: true,
          },
        },
        {
          path: `/${Routes.ADMIN}/${Routes.EXTERNAL_PAYMENT_APPROVAL}/:token`,
          name: "External payment approval domain",
          component: ExternalPaymentApproval,
          meta: {
            title: "外部決済手動承認",
            label: "External payment approval domain",
            guest: true,
          },
        },
      ],
    },
    //page 404
    //always at the end
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.MAINTENANCE}`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.MAINTENANCE}`,
      component: LayoutMaintenance,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.MAINTENANCE}`,
          name: "page maintenance",
          component: PageMaintenance,
          meta: {
            label: "page maintenance",
            userType: [0, 1, 2, 3, 4],
          },
        },
      ],
    },
    {
      path: `/${Routes.MAINTENANCE}`,
      redirect: `/${Routes.MAINTENANCE}`,
      component: LayoutMaintenance,
      children: [
        {
          path: `/${Routes.MAINTENANCE}`,
          name: "page maintenance domain",
          component: PageMaintenance,
          meta: {
            label: "page maintenance domain",
            userType: [0, 1, 2, 3, 4],
          },
        },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGES}/404`,
      component: LayoutAdmin,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/:pathMatch(.*)`,
          name: "page404adminAll",
          component: Page404Admin,
          meta: {
            label: "page404adminAll",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
        {
          path: `/${Routes.ADMIN}/:pathMatch(.*)`,
          name: "page404adminAll domain",
          component: Page404Admin,
          meta: {
            label: "page404adminAll domain",
            requiresAuth: true,
            userType: [0, 1, 2, 3],
          },
        },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.ADMIN}/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.CDEA_ADMIN}/${Routes.PAGES}/404`,
      component: LayoutMaster,
      children: [
        {
          path: `/${Routes.CDEA_ADMIN}/:pathMatch(.*)`,
          name: "page404CDEA_admin",
          component: Page404CDEA_Admin,
        },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      component: LayoutHome,
      children: [
        {
          path: `/${Routes.SHOP}/:shopId/:pathMatch(.*)`,
          name: "page404ShopAll",
          component: Page404,
          meta: {
            label: "page404ShopAll",
            requiresAuth: true,
            userType: [0, 4],
          },
        },
        {
          path: `/:pathMatch(.*)`,
          name: "page404ShopAll domain",
          component: Page404,
          meta: {
            label: "page404ShopAll domain",
            requiresAuth: true,
            userType: [0, 4],
          },
        },
      ],
    },
    {
      path: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      redirect: `/${Routes.SHOP}/:shopId/${Routes.PAGES}/404`,
      component: LayoutPage404,
      children: [
        {
          path: `/:pathMatch(.*)`,
          name: "page404noparam",
          component: Page404All,
          meta: {
            label: "page404noparam",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: `/${Routes.PAGES}/404`,
      redirect: `/${Routes.PAGES}/404`,
      component: LayoutPage404,
      children: [
        {
          path: `/:pathMatch(.*)`,
          name: "page404noparam domain",
          component: Page404All,
          meta: {
            label: "page404noparam",
            requiresAuth: true,
          },
        },
      ],
    },
  ];
}
function removeQueryParams() {
  if (window.location.pathname.includes(`/${Routes.SHOP}`)) {
    const shopId = window.location.pathname.split("/")[2];
    const api = localStorage.getItem(Constants.TOKEN_USER)
      ? Api.userRequestServer
      : Api.adminRequestServer;
    api
      .get(`/${Urls.SHOP_DOMAIN}/${shopId}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          if (data.data.domain && data.data.domain != null) {
            window.location.href = window.location.href.replace(
              `/${Routes.SHOP}/${shopId}`,
              ""
            );
          }
        }
      })
      .catch(() => {});
  }
  if (
    !window.location.pathname.includes(`/${Routes.BUY}/p`) &&
    !window.location.pathname.includes(`/${Routes.PAYMENT}/p`)
  ) {
    store.commit("set", ["modalShow", false]);
  } else {
    store.commit("set", ["modalShow", true]);
  }
}

router.beforeEach(async (to, from, next) => {
  // if (
  //   from.fullPath != "/" &&
  //   !from.path.includes("admin") &&
  //   to.path.includes("admin") &&
  //   localStorage.getItem(Constants.USER_ID) &&
  //   localStorage.getItem(Constants.SHOP_ID)
  // ) {
  //   const reqDel = {
  //     user_id: localStorage.getItem(Constants.USER_ID),
  //     shop_id: localStorage.getItem(Constants.SHOP_ID),
  //   };
  //   Api.userRequestServer
  //     .post(`/realtime-activity/delete`, reqDel)
  //     .then((response) => {
  //       const { data } = response;
  //       return data;
  //     });
  // }
  store.commit("set", ["routerNext", to]);
  store.commit("set", ["refer_url", from.fullPath]);
  const extConstant = to.params.shopId
    ? "_" + Constants.DOMAIN + "_" + to.params.shopId
    : "_" + Constants.DOMAIN;
  const token_user = localStorage.getItem(Constants.TOKEN_USER);
  const token_admin = localStorage.getItem(Constants.TOKEN_ADMIN + extConstant);
  const token_cdea = localStorage.getItem(Constants.TOKEN_CDEA);
  const fullPath = to.fullPath;
  const isAdmin =
    fullPath.includes(Constants.ADMIN) &&
    !fullPath.includes(Constants.CDEA_ADMIN);
  const isCDEA = fullPath.includes(Constants.CDEA_ADMIN);
  const shopId =
    localStorage.getItem(Constants.SHOP_ID) !== null
      ? localStorage.getItem(Constants.SHOP_ID)
      : to.params.shopId;
  const expires_at_user = localStorage.getItem(Constants.EXPIRES_AT_USER);
  const expires_at_admin = localStorage.getItem(
    Constants.EXPIRES_AT_ADMIN + extConstant
  );
  const expires_at_cdea = localStorage.getItem(Constants.EXPIRES_AT_CDEA);
  const shopIdParam = to.params.shopId;
  const shopCheck = to.params.shopId;
  const shopAdminCurrent =
    localStorage.getItem(Constants.ADMIN_USER_INFO + extConstant) &&
    JSON.parse(localStorage.getItem(Constants.ADMIN_USER_INFO + extConstant))
      .shop_id;
  const shopDomainAdminCurrent =
    localStorage.getItem(Constants.ADMIN_USER_INFO + extConstant) &&
    JSON.parse(localStorage.getItem(Constants.ADMIN_USER_INFO + extConstant))
      .shop_domain;
  var checkPage = true;
  var id_shop = to.params.shopId ? to.params.shopId : null;
  const userType = parseInt(localStorage.getItem(Constants.USER_TYPE_USER));
  if (
    !isAdmin &&
    !isCDEA &&
    token_user &&
    userType == Constants.USER_TYPE_DEFAULT["user_normal"]
  ) {
    let request = {
      domain: Constants.DOMAIN,
      shop_id: id_shop,
    };
    await Api.userRequestServer
      .post(`${Urls.SHOP}/${Urls.SETTING}/${Urls.GENERAL}`, request)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          if (data.data.length) {
            if (data.data[0].is_maintenance == 1) {
              if (data.data[0].url_maintenance) {
                window.location.href = data.data[0].url_maintenance;
              } else if (!fullPath.includes("maintenance")) {
                const hostName = window.location.origin;
                const href = router.resolve({
                  name: id_shop
                    ? "page maintenance"
                    : "page maintenance domain",
                  params: {
                    shopId: id_shop,
                  },
                }).href;
                window.location.href = hostName + href;
              }
            } else {
              if (fullPath.includes("maintenance")) {
                const hostName = window.location.origin;
                const href = router.resolve({
                  name: id_shop ? "login shop" : "login shop domain",
                  params: {
                    shopId: id_shop,
                  },
                }).href;
                window.location.href = hostName + href;
              }
            }
          }
        }
      })
      .catch(() => {
        return false;
      });
  }
  const pathOld = to.fullPath.replace(/[/]/g, "");
  let urlNext = {};
  if (to.params.shopId) {
    if (pathOld) {
      urlNext = {
        name: "login shop",
        params: { shopId: to.params.shopId },
        query: { redirect: to.fullPath },
      };
    } else {
      urlNext = {
        name: "login shop",
        params: { shopId: to.params.shopId },
      };
    }
  } else {
    if (pathOld) {
      urlNext = {
        name: "login shop domain",
        query: { redirect: to.fullPath },
      };
    } else {
      urlNext = {
        name: "login shop domain",
      };
    }
  }
  let url = to.params.shopId
    ? to.fullPath.slice(("/shop/" + to.params.shopId).length)
    : to.fullPath;
  if (url.startsWith("/")) url = url.substring(1);
  const formData = {
    shop_id: id_shop,
    domain: Constants.DOMAIN,
    short_url: url,
  };

  // check redirect of setting url
  await Api.userRequestServer
    .post(`/${Urls.URL}`, formData)
    .then(async (response) => {
      const { data } = response;
      if (data.success) {
        const url = data.data;
        window.location.href = url;
      } else {
        if (to.query.redirect && to.query.redirect.includes("/fixed-page/")) {
          let idFixed = to.query.redirect.replace("/fixed-page/", "");
          await Api.userRequestServer
            .get(`/${Urls.FIXED_PAGE}/${idFixed}`)
            .then((response) => {
              const { data } = response;
              if (data.success) {
                if (
                  !token_user &&
                  data.data.type_advance != 1 &&
                  data.data.is_login &&
                  (data.data.type == 8 || data.data.type == 9)
                ) {
                  localStorage.setItem(Constants.ID_MODAL_FIXED, data.data.id);
                  localStorage.setItem("displayedFixed", true);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              next();
              return { success: false };
            });
        }
        checkPage == true;
        return true;
      }
      return true;
    })
    .catch(() => {
      store.commit("set", ["modalShow", false]);
      return false;
    });
  if (
    to.name == "login cdea" &&
    isCDEA &&
    token_cdea &&
    new Date() <= new Date(expires_at_cdea)
  ) {
    next({
      name: "dashboard cdea",
    });
  }

  if (
    (to.name == "login admin" || to.name == "login admin domain") &&
    isAdmin &&
    token_admin &&
    new Date() <= new Date(expires_at_admin) &&
    (shopCheck
      ? shopCheck == shopAdminCurrent
      : shopDomainAdminCurrent == Constants.DOMAIN)
  ) {
    if (shopIdParam) {
      next({
        name: "Dashboard Admin",
        params: { shopId: shopCheck },
      });
    } else {
      next({
        name: "Dashboard Admin domain",
      });
    }
  }

  if (
    (to.name == "login shop" ||
      to.name == "login shop domain" ||
      to.name == "create member" ||
      to.name == "create member domain") &&
    !isAdmin &&
    !isCDEA &&
    token_user &&
    new Date() <= new Date(expires_at_user)
  ) {
    if (to.query.token) {
      var href = router.resolve({
        name: shopId ? "library" : "library domain",
      }).href;
      const request = {
        shop_id: shopId,
        slag_token: to.query.token,
        title: to.meta.title,
        referrer_url: window.location.origin + to.fullPath,
        url: window.location.origin + href,
        type: 1,
      };
      store.commit("set", ["modalShow", true]);
      let data = await Api.userRequestServer
        .post(`/${Urls.SLAG_TOKEN}`, request)
        .then((response) => {
          const { data } = response;
          store.commit("set", ["modalShow", false]);
          return data;
        })
        .catch((error) => {
          store.commit("set", ["modalShow", false]);
          console.log(error);
          next();
          return { success: false };
        });
      if (data.success) {
        const urlSite =
          location.protocol +
          "//" +
          location.hostname +
          (location.port ? ":" + location.port : "");
        if (data.data) {
          window.location.href = data.data.url_redirect;
        } else if (to.params.shopId) {
          // next({
          //   name: "library",
          //   params: {
          //     shopId: to.params.shopId,
          //     message: data.message,
          //   },
          // });
          window.location.href = urlSite + "/shop/" + to.params.shopId;
        } else {
          // router.push({
          //   name: "library domain",
          // });
          window.location.href = urlSite + "/";
        }
      }
    } else if (!to.query.redirect) {
      if (shopIdParam) {
        next({
          name: "library",
          params: { shopId: shopIdParam },
        });
      } else {
        next({
          name: "library domain",
        });
      }
    } else {
      next(to.query.redirect);
    }
  }

  if (
    to.name == "login cdea" ||
    to.name == "login admin" ||
    to.name == "login shop" ||
    to.name == "login shop domain"
  ) {
    next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const { userType } = to.meta;
    const userTypeUser = parseInt(
      localStorage.getItem(Constants.USER_TYPE_USER)
    );
    const userTypeAdmin = parseInt(
      localStorage.getItem(Constants.USER_TYPE_ADMIN + extConstant)
    );
    const userTypeCDEA = parseInt(
      localStorage.getItem(Constants.USER_TYPE_CDEA)
    );
    if (isAdmin) {
      if (
        new Date() > new Date(expires_at_admin) ||
        (shopCheck
          ? shopCheck != shopAdminCurrent
          : shopDomainAdminCurrent != Constants.DOMAIN)
      ) {
        if (shopIdParam) {
          next({
            name: "login admin",
            params: { shopId: shopId },
          });
        } else {
          next({
            name: "login admin domain",
          });
        }
      }
    } else if (isCDEA) {
      if (new Date() > new Date(expires_at_cdea)) {
        next({
          name: "login cdea",
        });
      }
    } else {
      if (
        (token_user && new Date() > new Date(expires_at_user)) ||
        (token_admin &&
          new Date() > new Date(expires_at_admin) &&
          (shopCheck
            ? shopCheck != shopAdminCurrent
            : shopDomainAdminCurrent != Constants.DOMAIN))
      ) {
        next(urlNext);
      }
    }

    if (token_user === null && token_admin === null && token_cdea === null) {
      const shopId = shopIdParam;
      const fullPath = to.fullPath;
      const isAdmin = fullPath.includes(Constants.ADMIN);
      if (
        isAdmin ||
        (shopCheck
          ? shopCheck != shopAdminCurrent
          : shopDomainAdminCurrent != Constants.DOMAIN)
      ) {
        if (shopIdParam) {
          next({
            name: "login admin",
            params: { shopId: shopId },
          });
        } else {
          next({
            name: "login admin domain",
          });
        }
      } else if (isCDEA) {
        next({
          name: "login cdea",
        });
      } else {
        next(urlNext);
      }
    }

    if (isAdmin) {
      if (
        token_admin !== null &&
        userType.includes(userTypeAdmin) &&
        (shopCheck
          ? shopCheck == shopAdminCurrent
          : shopDomainAdminCurrent == Constants.DOMAIN)
      ) {
        next();
      } else {
        if (shopIdParam) {
          next({
            name: "login admin",
            params: { shopId: shopCheck },
          });
        } else {
          next({
            name: "login admin domain",
          });
        }
      }
    } else if (isCDEA) {
      if (token_cdea !== null && userType.includes(userTypeCDEA)) {
        next();
      } else {
        next({
          name: "login cdea",
        });
      }
    } else {
      if (
        (token_user !== null && userType.includes(userTypeUser)) ||
        (token_admin !== null && userType.includes(userTypeAdmin))
      ) {
        next();
      } else {
        // 外部決済(ログイン履歴の無いブラウザ)
        if (to.fullPath.includes(Urls.EXTERNAL_PAYMENT_REDIRECT)) {
          const data = {
            identifier: to.fullPath.substring(to.fullPath.lastIndexOf("/") + 1),
            referrer: document.referrer,
          }
          await Api.userRequestServer
            .post(`/${Urls.EXTERNAL_PAYMENT_REDIRECT}`, data)
            .then( res => {
              localStorage.setItem('external_payment_history_id', res.data.data.history.id);
            })
            .catch( res => console.log('Failed', res));
        }
        next(urlNext);
      }
    }
    // next();
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (to.name == "staticPage" || to.name == "staticPage domain") {
      const reqData = {
        id: to.params.id,
        shop_id: shopId,
      };
      await Api.userRequestServer
        .get(`/${Urls.FIXED_PAGE}/${reqData.id}`)
        .then((response) => {
          // eslint-disable-next-line no-unused-vars
          var check = false;
          const { data } = response;
          if (data.success) {
            if (
              (data.data.type == 8 || (token_user && data.data.type == 9)) &&
              !localStorage.getItem("displayedFixed")
            ) {
              localStorage.setItem(Constants.ID_MODAL_FIXED, data.data.id);
            }
            if (data.data.is_login && !token_user) {
              next(urlNext);
              check = true;
            } else {
              localStorage.removeItem("displayedFixed");
              if (data.data.type == 8 || data.data.type == 9) {
                localStorage.setItem("isClick", false);
                if (shopIdParam) {
                  next({
                    name: "library",
                    params: { shopId: shopIdParam },
                  });
                } else {
                  next({
                    name: "library domain",
                  });
                }
              } else {
                next();
              }
            }
          } else {
            next();
          }
        })
        .catch(() => {
          return false;
        });
    } else if (to.name == "Before Login" || to.name == "Before Login domain") {
      // eslint-disable-next-line no-unused-vars
      var checkRedirect = false;
      const shop_id =
        localStorage.getItem(Constants.SHOP_ID) !== null
          ? localStorage.getItem(Constants.SHOP_ID)
          : to.params.shopId;
      const reqData = {
        shopId: shop_id,
        id: to.params.idContent,
      };
      if (token_user && new Date() <= new Date(expires_at_user)) {
        await Api.userRequestServer
          .get(
            `/${Urls.CONTENT}/${Urls.NO_CONDITION}/${reqData.id}?shop_id=${reqData.shopId}`
          )
          .then((response) => {
            // eslint-disable-next-line no-unused-vars
            var check = false;
            const { data } = response;
            if (data && data.success) {
              const detailStore = data.data;
              if (
                detailStore &&
                detailStore[0] &&
                detailStore[0].duplicate &&
                detailStore[0].duplicate == true
              ) {
                store.commit("set", ["modalShow", false]);
                if (detailStore[0].url_redirect) {
                  window.location.href = detailStore[0].url_redirect;
                  return;
                } else {
                  const infoContent = {
                    id: detailStore[0].id,
                    title: detailStore[0].content_title,
                  };
                  localStorage.setItem(
                    Constants.INFO_CONTENT,
                    JSON.stringify(infoContent)
                  );
                  if (to.params.shopId) {
                    next({
                      name: "page notice",
                      params: {
                        shopId: to.params.shopId,
                      },
                    });
                  } else {
                    next({
                      name: "page notice domain",
                    });
                  }
                  // next({
                  //   name: shopId ? "library" : "library domain",
                  // });
                  check = true;
                }
              } else {
                if (
                  !detailStore[0].sale_basic ||
                  !detailStore[0].sale_basic.column
                ) {
                  store.commit("set", ["modalShow", false]);
                  if (shopIdParam) {
                    next({
                      name: "login shop",
                      params: { shopId: shopIdParam },
                    });
                  } else {
                    next({
                      name: "login shop domain",
                    });
                  }
                } else if (shopIdParam) {
                  next({
                    name: "paymentBuy",
                    params: {
                      shopId: shopId,
                      id: to.params.idContent,
                    },
                  });
                  check = true;
                } else {
                  next({
                    name: "paymentBuy domain",
                    params: {
                      id: to.params.idContent,
                    },
                  });
                  check = true;
                }
              }
            } else {
              checkRedirect = true;
              store.commit("set", ["modalShow", false]);
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        checkRedirect = true;
      }
      if (checkRedirect) {
        next();
      }
    } else {
      next();
    }
  } else {
    // eslint-disable-next-line no-inner-declarations
    function isValid() {
      if (to.name !== null) {
        return true;
      } else {
        return false;
      }
    }
    // var str = to.fullPath;
    if (
      !isValid(to.params) ||
      to.name == "page404adminAll" ||
      to.name == "page404adminAll domain" ||
      to.name == "page404ShopAll" ||
      to.name == "page404ShopAll domain" ||
      to.name == "page404noparam" ||
      to.name == "page404noparam domain"
    ) {
      if (checkPage) {
        // eslint-disable-next-line no-debugger
        if (
          token_user === null &&
          (to.name == "page404ShopAll" || to.name == "page404ShopAll domain")
        ) {
          next(urlNext);
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }

  // eslint-disable-next-line no-unused-vars
  router.afterEach((to, from) => {
    let titleShop = localStorage.getItem(Constants.TITLE_SHOP);
    if (!titleShop || titleShop == "null") {
      titleShop = "";
    } else {
      titleShop = " | " + titleShop;
    }

    Vue.nextTick(() => {
      let title = "";
      if (to.meta.title) title += to.meta.title;
      document.title = title + titleShop;
    });
  });
});

export default router;
